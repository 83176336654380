import React from "react"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"
import Breadcrumbs from "../components/breadcrumbs"

const IndexPage = () => (
  <Layout>
    <SEO title="How Notary Works - JustNotaries" />
    <Breadcrumbs
      crumbs={[
        {
          label: `Home`,
          url: `/`,
        },
        { label: `How It Works` },
      ]}
    />
    <Container
      element="section"
      css={css`
        padding-top: 60px;
        padding-bottom: 60px;
      `}
    >
      <h1
        css={css`
          padding-bottom: 0.5em;
        `}
      >
        How Mobile Notary Works
      </h1>

      <h2
        css={css`
          margin: 0;
          padding: 1em 0;
        `}
      >
        1. Schedule Your Appointment
      </h2>
      <p>
        You may{" "}
        <a
          href="https://calendly.com/loganstellway/30min?back=1&month=2020-02"
          rel="noopener noreferrer"
        >
          schedule an appointment
        </a>{" "}
        with the online scheduler. Appointments usually take 30 minutes to an
        hour, depending on document size.
      </p>
      <h2
        css={css`
          margin: 0;
          padding: 1em 0;
        `}
      >
        2. Follow Up
      </h2>
      <p>
        Once an appointment has been made, we will follow up within 24 hours.
      </p>
      <h2
        css={css`
          margin: 0;
          padding: 1em 0;
        `}
      >
        3. Document Signing &amp; Payment
      </h2>
      <p>
        I will meet you at our decided location with the prepared documents.
        Payment will be taken. Available payment options are cash, check &amp;
        credit card.{" "}
      </p>
    </Container>
  </Layout>
)

export default IndexPage
